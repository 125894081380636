import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { promises } from 'dns';
import { environment } from '../../../environments/environment';
import * as _ from "lodash";
import { BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import { switchMap } from 'rxjs/operators';
// import { OrderService } from 'app/modules/sales/order.service';
declare const $: any;

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USER_PERMISSION_LEVEL = 'user-permission-level';
@Injectable({
    providedIn: 'root'
})
export class CommonAPIService {

    assignLineItemFlag = false;
    USER_ADMIN = 'Admin';
    USER_CSD = 'Collegiate Program Coordinator';
    USER_OM = 'Operations Manager';
    USER_SP = 'Sales Partner';
    USER_SELLER = 'Seller'; // Seller and Sales Partner both are same
    USER_DP = 'Design Partner';
    USER_PP = 'Production Partner';
    USER_NOT_AUTHORIZED = 'Not Authorized';
    SCREEN_PRINTING = 'Screen Printing';
    DIRECT_TO_GARMENT_PRINTING = 'Direct to Garment';
    EMBROIDERY_PRINTING = 'Embroidery';
    VINYL_PRINTING = 'Vinyl';
    FINISHINGS = 'Finishings';
    SHIPPING_METHOD = ['Individual','Bulk'];
    BULK_SHIPPING = 'Bulk';
    INDIVIDUAL_SHIPPING = 'Individual';
    allSearchTypesMasters: any;
    SHIP_METHOD_TYPES = [{shipMethodLabel: 'Individual Shipping', shipMethodValue: 'Individual'}, {shipMethodLabel: 'Bulk Shipping', shipMethodValue: 'Bulk'}];

    savePricingFunction: BehaviorSubject<any> = new BehaviorSubject(null);
    currentProductionPartner: BehaviorSubject<any> = new BehaviorSubject(null);
    currentProductionPartnerCompany: BehaviorSubject<any> = new BehaviorSubject(null);
    isPrintMethodTabChange: BehaviorSubject<any> = new BehaviorSubject(null);
    getVinylData: BehaviorSubject<any> = new BehaviorSubject(false);
    isOrderCanceled: BehaviorSubject<any> = new BehaviorSubject(false);

    searchCall = new Subject();
    searchResult = new Subject();
    searchAdminCSDUsersCall = new Subject();
    searchAdminCSDUsersResult = new Subject();
    searchSPUsersCall = new Subject();
    searchSPUsersResult = new Subject();
    // checkSaveOrderCall = new Subject();
    // checkSaveOrderResponse = new Subject();
    // saveOrderCall = new Subject();
    // saveOrderResponse = new Subject();
    // getCurrentDateTimeCall = new Subject();
    // getCurrentDateTimeResponse = new Subject();
    checkSaveOrder:Subscription;
    saveOrder :Subscription;
    getCurrentDateTime : Subscription;

    pricingFunctions: any = {};
    productionPricing: any = {};

    private allPrintMethodNames: string[] = [];
    printMethods: any = {};
    vinylData: any = {};
    productionPartners: any = {};
    pricingObject: any = {};
    ppCompanies: any = [];
    ppMembers: any = [];
    listProductionPartners: any = [];
    systemSettings: any = [];
    isSettingsRetrieved: boolean = false;
    proccesing: Boolean = false;
    private defaultPricings = new BehaviorSubject<any>(null);
    defaultPricings$ = this.defaultPricings.asObservable();

    constructor(
        private http: HttpClient,
        // private orderService: OrderService
    ) { 
        this.searchCall.pipe(
            switchMap((requestParams:any)=>{
                // console.log('getting request params',requestParams);
                return  this.http.post(requestParams.postUrl, requestParams.requestData, {headers : requestParams.requestHeaders})
            })
        )
        .subscribe((response)=>{
            this.searchResult.next(response)
        },
        (error)=>{
            console.log('error');
        })

        this.searchAdminCSDUsersCall.pipe(
            switchMap((requestParams:any)=>{
                // console.log('getting request params',requestParams);
                return  this.http.post(requestParams.postUrl, requestParams.requestData, {headers : requestParams.requestHeaders})
            })
        )
        .subscribe((response)=>{
            this.searchAdminCSDUsersResult.next(response)
        },
        (error)=>{
            console.log('error');
        })

        this.searchSPUsersCall.pipe(
            switchMap((requestParams:any)=>{
                // console.log('getting request params',requestParams);
                return  this.http.post(requestParams.postUrl, requestParams.requestData, {headers : requestParams.requestHeaders})
            })
        )
        .subscribe((response)=>{
            this.searchSPUsersResult.next(response)
        },
        (error)=>{
            console.log('error');
        })

        // this.checkSaveOrderCall.pipe(
        //     switchMap((requestParams:any)=>{
        //         // console.log('getting request params',requestParams);
        //         return  this.http.post(requestParams.postUrl, requestParams.requestData, {headers : requestParams.requestHeaders})
        //     })
        // )
        // .subscribe((response)=>{
        //     this.checkSaveOrderResponse.next(response)
        // },
        // (error)=>{
        //     console.log('error');
        // })

        // this.saveOrderCall.pipe(
        //     switchMap((requestParams:any)=>{
        //         // console.log('getting request params',requestParams);
        //         return  this.http.post(requestParams.postUrl, requestParams.requestData, {headers : requestParams.requestHeaders})
        //     })
        // )
        // .subscribe((response)=>{
        //     this.saveOrderResponse.next(response)
        // },
        // (error)=>{
        //     console.log('error');
        // })

        // this.getCurrentDateTimeCall.pipe(
        //     switchMap((requestParams:any)=>{
        //         // console.log('getting request params',requestParams);
        //         return  this.http.post(requestParams.postUrl, requestParams.requestData, {headers : requestParams.requestHeaders})
        //     })
        // )
        // .subscribe((response)=>{
        //     this.getCurrentDateTimeResponse.next(response)
        // },
        // (error)=>{
        //     console.log('error');
        // })
    }

    fnHttpPostCallWithObservable(targetUrl, postUrl, requestParameters:any, appendHeaderKey = '', appendHeaderValue = '') : Observable<any>{
        let authToken = this.fnGetAuthToken();
        let headers = new HttpHeaders({
            'Target-URL': targetUrl,
            'Access-Control-Allow-Origin': '*',
            'Access-Token': environment.config.api_key
        });
        if (authToken && authToken.length > 0) {
            headers = headers.append('Auth-Token', authToken);
        }
        if (appendHeaderKey != '' && appendHeaderValue != '') {
            headers = headers.append(appendHeaderKey, appendHeaderValue);
        }
        if (appendHeaderKey=="Content-Type") {
            headers = headers.append("Content-Type", appendHeaderValue);
                    
        }else{
            headers = headers.append("Content-Type",'application/json');
        }

        return this.http.post<any>(postUrl,requestParameters, { headers: headers });
    }

    fnHttpPostCall(targetUrl, postUrl, requestParameters:any, appendHeaderKey = '', appendHeaderValue = '') {
        try {
            return new Promise(async (resolve, reject) => {
                let authToken = this.fnGetAuthToken();
                let headers = new HttpHeaders({
                    'Target-URL': targetUrl,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Token': environment.config.api_key
                });
                if (authToken && authToken.length > 0) {
                    headers = headers.append('Auth-Token', authToken);
                }
                if (appendHeaderKey != '' && appendHeaderValue != '') {
                    headers = headers.append(appendHeaderKey, appendHeaderValue);
                }
                if (appendHeaderKey=="Content-Type") {
                    headers = headers.append("Content-Type", appendHeaderValue);
                    
                }else{
                    headers = headers.append("Content-Type",'application/json');
                }

                this.http.post(postUrl,requestParameters, { headers: headers }).subscribe((data: any) => {

                    resolve(data);
                }, err => {
                    console.error(err);
                    reject(err);
                });
            });
        } catch (error) {
            console.log(error);
        }
    }

    fnFetchHttpRequesHeaders(targetUrl, appendHeaderKey = '', appendHeaderValue = ''){
        let authToken = this.fnGetAuthToken();
        let headers = new HttpHeaders({
            'Target-URL': targetUrl,
            'Access-Control-Allow-Origin': '*',
            'Access-Token': environment.config.api_key
        });
        if (authToken && authToken.length > 0) {
            headers = headers.append('Auth-Token', authToken);
        }
        if (appendHeaderKey != '' && appendHeaderValue != '') {
            headers = headers.append(appendHeaderKey, appendHeaderValue);
        }
        if (appendHeaderKey=="Content-Type") {
            headers = headers.append("Content-Type", appendHeaderValue);
                    
        }else{
            headers = headers.append("Content-Type",'application/json');
        }
        return headers;
    }

    fnFormDataHttpPostCall(targetUrl, postUrl, requestParameters, appendHeaderKey = '', appendHeaderValue = '') {
        try {
            return new Promise(async (resolve, reject) => {
                let authToken = this.fnGetAuthToken();
                let headers = new HttpHeaders({
                    // "Content-Type":"multipart/form-data",
                    'Target-URL': targetUrl,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Token': environment.config.api_key
                });
                if (authToken && authToken.length > 0) {
                    headers = headers.append('Auth-Token', authToken);
                }
                if (appendHeaderKey != '' && appendHeaderValue != '') {
                    headers = headers.append(appendHeaderKey, appendHeaderValue);
                }

                this.http.post(postUrl, requestParameters, { headers: headers }).subscribe((data: any) => {
                    resolve(data);
                }, err => {
                    console.error(err);
                    reject(err);
                });
            });
        } catch (error) {
            console.log(error);
        }
    }
    fnGetAllUsers() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllUsers';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetSelfCheckout(paymentIntent) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getSelfCheckoutData';
                let requestParameters = {'paymentIntent': paymentIntent};
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                console.log('result ', result);
                
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fngetPermissionLevel() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getPermissionLevel';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnupdateEmailId(user_email,user_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'updateEmailId';
                let requestParameters = {'email': user_email,'user_id':user_id };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnupdateUserbyId(Formvalues) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'updateUserbyId';
                let requestParameters = Formvalues;               
                let result = await this.fnFormDataHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnsignupUser(value) {
        try {
            // let fd = new FormData();
            // fd.append('email',data)
            return new Promise(async (resolve, reject) => {
                
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'signup';
                let requestParameters = value;          
                let result = await this.fnHttpPostCall(targetUrl, postUrl,requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetProductionPartnerUserByUID(userUID: Number) {
        try {
            return new Promise(async (resolve, reject) => {
                // Set the target URL to the configured proxy URL
                let targetUrl = environment.config.proxy_url;
                
                // Construct the post URL by appending the specific endpoint
                let postUrl = targetUrl + 'getProductionPartnerUserByUID';
                
                // Prepare the request parameters object
                let requestParameters = {
                    userUID
                };
                
                // Perform an HTTP POST call using fnHttpPostCall function
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                
                // Resolve the promise with the result
                resolve(result);
            })
        } catch (err) {
            // If an error occurs, log it to the console
            console.log(err);
        }
    }    

    fnGetAllProductionPartnerUsers() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllProductionPartnerUsers';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    async getAllPpCompanies() {
        try {
            while (this.ppCompanies.length === 0) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }
           return this.ppCompanies;
        } catch (err) {
            console.log(err);
        }
    }

    fnGetAllPPCompnaies() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllPPCompanies';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetAllCSDUsers() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllCSDUsers';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    getPrintMethodByName(pp_name) {
        try {
           return this.getPrintMethodData(pp_name);
        } catch (err) {
            console.log(err);
        }
    }

    fnGetPrintMethodByName(pp_name) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getPrintMethodByName';
                let requestParameters = { 'pp_name': pp_name };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetAllDesignerPartnerUsers() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllDesignerPartnerUsers';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnUpdateUsers() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'update-users-profile-mysql';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetUserByEmail(user_email) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getUserByEmail';
                let requestParameters = { 'email': user_email };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetUserById(user_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getUserById';
                let requestParameters = { 'user_id': user_id };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnDeleteUser(user_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'deleteUser';
                let requestParameters = { 'user_id': user_id };
                // let authKey = "access-token";
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetArtLocations() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getArtLocations';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetArtworkPostionByLocation(artworkLocation) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getArtworkPostionByLocation';
                let requestParameters = { 'artworkLocation': artworkLocation };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetPrintMethods() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getPrintMethods';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnAuthLoginAPI(email) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'login';
                let requestParameters = { 'email': email };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnAuthLogoutAPI(email) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'logout';
                let requestParameters = { 'email': email };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    async fnAuthLogin(email) {
        try {
            return new Promise(async (resolve, reject) => {
                let user: any = await this.fnAuthLoginAPI(email);
                if (user && user.access_token) {
                    this.fnSaveToken(user.access_token);
                    this.fnSaveUserPermissionLevel(user.permissionLevel);
                    this.fnSaveUser(user);
                    resolve(true);
                } else {
                    this.fnAuthLogOut(email);
                    resolve(false);
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    async fnAuthLogOut(email) {
        try {
            let user: any = await this.fnAuthLogoutAPI(email);
            if (user) {
                localStorage.removeItem(TOKEN_KEY);
                localStorage.removeItem(USER_PERMISSION_LEVEL);
                localStorage.removeItem(USER_KEY);
            }
        } catch (e) {
            console.log(e);
        }
    }

    // fnAuthLogOut(): void {
    //     localStorage.removeItem(TOKEN_KEY);
    //     localStorage.removeItem(USER_PERMISSION_LEVEL);
    //     localStorage.removeItem(USER_KEY);
    // }

    fnSaveToken(token: string): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.setItem(TOKEN_KEY, token);
    }

    fnSaveUserPermissionLevel(token: string): void {
        localStorage.removeItem(USER_PERMISSION_LEVEL);
        localStorage.setItem(USER_PERMISSION_LEVEL, token);
    }

    fnGetAuthToken(): string | null {
        return localStorage.getItem(TOKEN_KEY);
    }

    fnSaveUser(user: any): void {
        localStorage.removeItem(USER_KEY);
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    fnGetUser(): any {
        const user = localStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user);
        }
        return {};
    }

    fnGetProductionPricing(print_method_id, pp_company_id, flag = null) {
        try {
            if (!(this.productionPricing[print_method_id] && this.productionPricing[print_method_id][pp_company_id]) || flag == "reload") {
                return new Promise(async (resolve, reject) => {
                    let requestParameters = { 'pm_id': print_method_id, 'pp_company_id': pp_company_id };
                    let targetUrl = environment.config.proxy_url;
                    let postUrl = targetUrl + 'getProductionPricing';
                    let productionPricing: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                    this.productionPricing[print_method_id] = {};
                    this.productionPricing[print_method_id][pp_company_id] = productionPricing;
                    resolve(productionPricing);
                });
            }
            else {
                return new Promise((resolve) => {resolve(this.productionPricing[print_method_id][pp_company_id])});
            }
        } catch (e) {
            console.log(e);
        }
    }

    getVinylPricingByPpCompany(ppCompanyId) {
        try {
           return this.getVinylPricing(ppCompanyId);
        } catch (err) {
            console.log(err);
        }
    }

    async fnGetVinylPricing(print_method_id, ppCompanyId) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { 'pm_id': print_method_id, 'pp_company_id': ppCompanyId };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getVinylPricing';
                let productionPricing: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(productionPricing);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSaveTables(ppCompanyId, activeTabId, result) {
        try {
            let data = {
                ppCompanyId: ppCompanyId,
                printMethodId: activeTabId,
                allPricingData: result
            }
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'saveProductionPricing';
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            })
        } catch (e) {
            console.log(e);
        }
    }

    fnGetRushFees(pp_company_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { 'pp_company_id': pp_company_id };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getRushFees';
                let productionPricing: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(productionPricing);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetAllRushFees() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllRushFees';
                let productionPricing: any = await this.fnHttpPostCall(targetUrl, postUrl, undefined);
                resolve(productionPricing);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetInitialData(styleName) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { 'style_name': styleName };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getInitialData';
                let initialData: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
               
                resolve(initialData);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSaveRushFees(ppCompanyId, rushFeesData) {
        try {
            let data = {
                ppCompanyId: ppCompanyId,
                rushFeesData: rushFeesData
            }
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'saveRushFees';
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            })
        } catch (e) {
            console.log(e);
        }
    }

    fnGetAddons(print_method_id, pp_company_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { 'pp_company_id': pp_company_id, 'pm_id':  print_method_id };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAddons';
                let productionPricing: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(productionPricing);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnGetScreenFees(initialProductionPartnerId) {
        try {
            let screenFees;                
            if(this.productionPartners[initialProductionPartnerId]){
                screenFees = this.productionPartners[initialProductionPartnerId]['screen_fees']
            }
            return screenFees;
        } catch (e) {
            console.log(e);
        }
    }
    fnSaveAddons(pmId, ppCompanyId, addons) {
        try {
            let data = {
                pm_id: pmId,
                pp_company_id: ppCompanyId,
                addons: addons
            }
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'saveAddons';
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            })
        } catch (e) {
            console.log(e);
        }
    }
    fnSaveScreenFees(ppCompanyId, screenFees) {
        try {
            let data = {
                pp_company_id: ppCompanyId,
                screenFees: screenFees
            }
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'saveScreenFees';
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            })
        } catch (e) {
            console.log(e);
        }
    }

    fnGetCategories() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getCategories';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (e) {
            console.log(e);
        }
    }

    fnGetSystemSettings() {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = {};
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getSystemSettings';
                let productionPricing: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                let rObj = {};
                productionPricing.forEach(obj => {
                    rObj[obj.key] = obj.value;
                });
                resolve(rObj);
                this.isSettingsRetrieved = true;
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetprintLocation() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getPrintLocation';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (e) {
            console.log(e);
        }
    }

    fnSaveOrder(order) {
        try {
            return new Promise(async (resolve, reject) => {
                let isCompleted;
                let isSaveOrderCompleted = true;
                if (sessionStorage.getItem('is_save_order_completed') == 'false') {
                    isSaveOrderCompleted = false;
                } else {
                    isSaveOrderCompleted = true;
                }
                if (!isSaveOrderCompleted) {
                    let orderName = order && order.job ? order.job : 'Test Order';
                    sessionStorage.setItem('save_order_data', orderName);
                    return;
                }
                let allowOrderToSave = true;
                if (order.order_id != undefined || order.order_id > 0 ) {
                    let allowOrderToSaveStatus = await this.fnCheckAllowToSaveOrder(order.order_id);
                    if (!allowOrderToSaveStatus) {
                        if (sessionStorage.getItem("show_refresh_modal")) {
                            sessionStorage.removeItem('show_refresh_modal');
                        }
                        sessionStorage.setItem('show_refresh_modal', 'true');
                        allowOrderToSave = false;
                    }
                }
                if (isSaveOrderCompleted && allowOrderToSave) {
                    sessionStorage.setItem('is_save_order_completed', 'false');
                    this.proccesing = false;
                    let requestParameters = { 'order': order };
                    let targetUrl = environment.config.proxy_url;
                    let postUrl = targetUrl + 'saveOrder';
                    // const requestParams = {
                    //     requestHeaders,
                    //     postUrl,
                    //     requestData
                    // }
                    // this.saveOrderCall.next(requestParams);
                    // this.saveOrderResponse.subscribe(async (result)=>{
                    //     if (result) {
                    //             // setTimeout(async () => {
                    //             //     await this.fnSetPageLoadedDateTime();
                    //             // }, 2000);
                    //             console.log('result',result);
                    //             console.log('coming here in subscribe');
                    //             await this.fnSetPageLoadedDateTime(true);
                    //             resolve(result);
                    //         } else {
                    //             resolve(false);
                    //         }
                    // })
                    if(this.saveOrder){
                        this.saveOrder.unsubscribe();
                    }
                    this.saveOrder = this.fnHttpPostCallWithObservable(targetUrl, postUrl, requestParameters).subscribe(
                        async (result)=>{
                            if (result) {
                                // setTimeout(async () => {
                                //     await this.fnSetPageLoadedDateTime();
                                // }, 2000);
                                await this.fnSetPageLoadedDateTime(true);
                                sessionStorage.setItem('is_save_order_completed', 'true');
                                resolve(result);
                            } else {
                                resolve(false);
                            }
                        }
                    )
                    // let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                    // if (result) {
                    //     // setTimeout(async () => {
                    //     //     await this.fnSetPageLoadedDateTime();
                    //     // }, 2000);
                    //     await this.fnSetPageLoadedDateTime(true);
                    //     resolve(result);
                    // } else {
                    //     resolve(false);
                    // }
                } else {
                    resolve(false);
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    getPricingByPpCompanyAndPrintMethodId(printMethodId, ppCompanyId) {
        try {
           return this.getPricingData(printMethodId, ppCompanyId);
        } catch (err) {
            console.log(err);
        }
    }

    fnGeneratePricingFunction(printMethodId, ppCompanyId, flag = null) {
        try {
            if (!this.pricingFunctions[printMethodId] || !this.pricingFunctions[printMethodId][ppCompanyId] || flag == "reload") {
                return new Promise(async (resolve, reject) => {
                    let targetUrl = environment.config.proxy_url;
                    let postUrl = targetUrl + 'generatePricingFunction';
                    // let requestParameters = { printMethodId, productionPartnerId, ppCompanyId };
                    let requestParameters = { printMethodId, ppCompanyId };
                    let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                    this.pricingFunctions[printMethodId] = {};
                    this.pricingFunctions[printMethodId][ppCompanyId] = result;
                    resolve(result);
                });
            }
            else {
                return new Promise((resolve) => {resolve(this.pricingFunctions[printMethodId][ppCompanyId])});
            }
        } catch (e) {
            console.log(e);
        }
    }

    setSessionStorageSaveOrderComplete() {
        sessionStorage.setItem('is_save_order_completed', 'true');
        sessionStorage.removeItem('save_order_data');
    }

    fnGetOrder(order_id, pageName? : string) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = pageName ? (pageName === 'order-confirm' ? { 'order_no': order_id } : { 'order_id': order_id }) : { 'order_id': order_id };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getOrder';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                console.log('result===>', result);
                
                if (result) {
                    this.setSessionStorageSaveOrderComplete();
                }
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetInitOrder(order_no) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { 'order_no': order_no };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getInitOrder';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                console.log('result===>', result);
                
                if (result) {
                    this.setSessionStorageSaveOrderComplete();
                }
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSavePricingFunction(printMethodId, userId, printFunctionData, ppCompanyId) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { printMethodId, userId, printFunctionData, ppCompanyId };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'savePricingFunction';
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    async createPaymentIntentFunction(amount, currency, payload) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { amount, currency, payload };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'payment';
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }
    async confirmPaymentIntentFunction(token, paymentIntentId) {
        return new Promise(async (resolve, reject) => {
            let requestParameters = { token, paymentIntentId };
            let targetUrl = environment.config.proxy_url;
            let postUrl = targetUrl + 'confirmStripePayment';
            try {
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            } catch (error) {
                reject(error); // Reject the promise with the error
            }
        });
    }

    fnSaveVinylPricing(printMethodId, userId, ppCompanyId, vinylData) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { printMethodId, userId, ppCompanyId, vinylData };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'saveVinylPricing';
                let response: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetGarmentByUpmerchItem(upmerchItem) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = {
                    'upmerchItem': upmerchItem,
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getProductByUpMerchItem';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                if (result && result.length > 0) {
                    resolve(result[0]);
                } else {
                    resolve([]);
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetOrdersByFilters(filters, recordsFrom) {
        try {
            return new Promise(async (resolve, reject) => {
                const requestParameters = {
                    filters, recordsFrom
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getOrdersByFilters';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnAppendOrderDataToGoogleSheet(orderData) {
        try {
            return new Promise(async (resolve, reject) => {
                const requestParameters = {
                    orderData
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'appendOrderDataToGoogleSheet';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnGetOrdersTotalProfitRevenue(filters) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = {
                    'filters' : filters
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getOrdersTotalProfitRevenue';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetProductionOrdersTotalInvoice(filters) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = {
                    'filters' : filters
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getProductionOrdersTotalInvoice';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetSalesOrdersStatusCount(filters) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = {
                    'filters' : filters
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getSalesOrdersStatusCount';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetDesignOrdersStatusCount(filters) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = {
                    'filters' : filters
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getDesignOrdersStatusCount';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetProductionOrdersStatusCount(filters) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = {
                    'filters' : filters
                };
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getProductionOrdersStatusCount';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnCancelOrder(order_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'cancelOrder';
                let requestParameters = { 'order_id': order_id };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnRequestFinalArtworkFile(orderId, flag) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'requestFinalArtworkFile';
                let requestParameters = { 'order_id': orderId, 'flag': flag };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetRDBMSServerCurrentDateTime() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getRDBMSServerCurrentDateTime';
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnCheckAllowToSaveOrder(order_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                // const requestHeaders = this.fnFetchHttpRequesHeaders(targetUrl);
                let postUrl = targetUrl + 'checkAllowToSaveOrder';
                // let requestData = { 
                //     'order_id': order_id,
                //     'page_loaded_at' : sessionStorage.getItem('page_loaded_at')
                // };
                let requestParameters = { 
                    'order_id': order_id,
                    'page_loaded_at' : sessionStorage.getItem('page_loaded_at')
                };
                // const requestParams = {
                //     requestHeaders,
                //     postUrl,
                //     requestData
                // }
                // this.checkSaveOrderCall.next(requestParams);
                // let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                // resolve(result);
                if(this.checkSaveOrder)
                {
                    this.checkSaveOrder.unsubscribe();
                }
                this.checkSaveOrder = this.fnHttpPostCallWithObservable(targetUrl, postUrl, requestParameters).subscribe(
                    (res)=>{
                        // console.log('getting result',res);
                        resolve(res)
                    }
                )
                // this.checkSaveOrderResponse.subscribe((result)=>{
                //     console.log('should not keep coming here',result);
                //     resolve(result)
                // })
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetCurrentAmericaChicagoDateTime() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                const requestHeaders = this.fnFetchHttpRequesHeaders(targetUrl);
                let postUrl = targetUrl + 'getCurrentAmericaChicagoDateTime';
                let requestParameters = {};
                // const requestParams = {
                //     requestHeaders,
                //     postUrl,
                //     requestData
                // }
                // this.getCurrentDateTimeCall.next(requestParams);
                // this.getCurrentDateTimeResponse.subscribe((result:any)=>{
                //     if (result && result.currentDate)  {
                //         resolve(result.currentDate);
                //     } else {
                //         resolve('');
                //     }
                // })
                // let result:any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                if(this.getCurrentDateTime){
                    this.getCurrentDateTime.unsubscribe();
                }
                this.getCurrentDateTime = this.fnHttpPostCallWithObservable(targetUrl, postUrl, requestParameters).subscribe(
                    (result)=>{
                        if (result && result.currentDate)  {
                            resolve(result.currentDate);
                        } else {
                            resolve('');
                        }
                    }
                )
            })
        } catch (err) {
            console.log(err);
        }
    }

    async fnSetPageLoadedDateTime(afterSave = false) {
        try {
            let currentDateTime:any = await this.fnGetCurrentAmericaChicagoDateTime();
            if (afterSave) {
                currentDateTime = this.addSecondInDateTime(currentDateTime)
            }
            if (sessionStorage.getItem("page_loaded_at")) {
                sessionStorage.removeItem('page_loaded_at');
            }
            sessionStorage.setItem('page_loaded_at', currentDateTime);
        } catch (err) {
            console.log(err);
        }
    }

    addSecondInDateTime(currentDateTime): string {
        let d = new Date(currentDateTime)
        d.setSeconds ( d.getSeconds() + 1 )
        let month = d.getMonth() + 1;
        let dstr = d.getFullYear()+'-'+month+'-'+d.getDate()+' '+d.getHours()+':'+d.getMinutes()+':'+d.getSeconds();
        return dstr;
    }

    fnCheckAllowToSaveUser(user_id) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'checkAllowToSaveUser';
                let requestParameters = { 
                    'user_id': user_id,
                    'page_loaded_at' : sessionStorage.getItem('page_loaded_at')
                };
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }
    
    fnGetUsersForChatNotification(orderId, senderId) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getUsersForChatNotification';
                const requestData = {orderId, senderId};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnCheckIfUserIsAllowedToBeNotifiedInApp(userId, notificationKey) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'checkIfUserIsAllowedToBeNotifiedForApp';
                const requestData = {userId, notificationKey};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetUsersForNotificationByPermissionLevel(permissionLevel, notificationKey) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getUsersByPermissionLevelAndNotificationAllowance';
                const requestData = {permissionLevel, notificationKey};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetUsersByPermissionLevel(permissionLevel) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getUsersByPermissionLevel';
                const requestData = {permissionLevel};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetCSDUserFromSalesPartner(salesPartnerId) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getCSDUserFromSalesPartner';
                const requestData = {salesPartnerId};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetOrderJob(orderId) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getOrderJob';
                const requestData = {orderId};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendToPPNewOrderEmails(orderId) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendToPPNewOrderEmails';
                const requestData = {orderId};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendEmailToSPuserForSendToProduction(order_id, email, SPid, key) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendEmailToSPuserForSendToProduction';
                const requestData = {order_id, email, SPid, key};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendEmailToDPArtworkFileRequested(order_id, email, dpId, key) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendEmailToDPArtworkFileRequested';
                const requestData = {order_id, email, dpId, key};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnLockUnlockOrderPricing(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'lockUnlockOrderPricing';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetOrderPricingLockStatus(orderId) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getOrderPricingLockStatus';
                const requestData = {orderId};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendGreekLicensingApprovedEmail(userId, email, orderId) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendGreekLicensingApprovedEmail';
                const requestData = {userId, email, orderId};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendGreekLicensingRejectedEmail(userId, email, orderId) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendGreekLicensingRejectedEmail';
                const requestData = {userId, email, orderId};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendOrderShippedEmail(orderId, salesPartnerId, salesPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendOrderShippedEmail';
                const requestData = {orderId, salesPartnerId, salesPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendComissionProcessedEmail(orderId, salesPartnerId, salesPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendCommissionProcessedEmail';
                const requestData = {orderId, salesPartnerId, salesPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendPaymentPendingEmail(orderId, salesPartnerId, salesPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                // const postUrl = targetUrl + 'sendCommissionProcessedEmail';
                const postUrl = targetUrl + 'sendPaymentPendingEmail';
                const requestData = {orderId, salesPartnerId, salesPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendPaymentReceivedEmail(orderId, salesPartnerId, salesPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendPaymentReceivedEmail';
                const requestData = {orderId, salesPartnerId, salesPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendArtworkRejectedEmail(orderId, designPartnerId, designPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendArtworkRejectedEmail';
                const requestData = {orderId, designPartnerId, designPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendArtworkCompleteEmail(orderId, designPartnerId, designPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendArtworkCompleteEmail';
                const requestData = {orderId, designPartnerId, designPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendArtworkApprovalEmail(orderId, salesPartnerId, salesPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendArtworkApprovalEmail';
                const requestData = {orderId, salesPartnerId, salesPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendStatusChangeEmail(orderId, salesPartnerId, salesPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendStatusChangeEmail';
                const requestData = {orderId, salesPartnerId, salesPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendNewPPOrderEmail(orderId, productionPartnerId, productionPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendNewPPOrderEmail';
                const requestData = {orderId, productionPartnerId, productionPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSendInvoicePaidEmail(orderId, productionPartnerId, productionPartnerEmail) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'sendInvoicePaidEmail';
                const requestData = {orderId, productionPartnerId, productionPartnerEmail};
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSaveReqDataInApiLogs(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'saveReqDataInApiLogs';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSaveResDataInApiLogs(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'saveResDataInApiLogs';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    getNotificationOptionsByPermissionLevel(permissionLevel) {
        try {
            console.log(permissionLevel)
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getNotificationOptionsByPermissionLevel';
                let requestParameters = {"permission_level": permissionLevel};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }


    async setStrUrlString(str) {
        if (!str.includes(' ')) {
          return str.toLowerCase();
        }
        await this.fnGetAllSearchTypesMasterData()
        let keywords = this.getKeywords(str);
        let keywordString = keywords.join('-')
        return keywordString;
    }

    fnGetAllSearchTypesMasterData() {
        try {
            return new Promise(async (resolve, reject) => {
                if (this.allSearchTypesMasters) {
                    resolve(true)
                    return true;
                }
                let allSearchTypesJson = localStorage.getItem('search_types');
                let allSearchTypes = []
                if (!allSearchTypesJson) {
                  let targetUrl = environment.config.proxy_url;
                  let postUrl = targetUrl + 'getSearchTypeMasters';
                  let requestParameters = [];
                  let searchTypesMasters = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                  allSearchTypes = [...searchTypesMasters['brands'], ...searchTypesMasters['categories'], ...searchTypesMasters['colors'], ...searchTypesMasters['sizes']];
                  localStorage.setItem('search_types', JSON.stringify(allSearchTypes));
                } else {
                  allSearchTypes = JSON.parse(allSearchTypesJson);
                }
                this.allSearchTypesMasters = allSearchTypes;
                console.log('fnGetAllSearchTypesMasterData ends')
                resolve(true);
            });
        } catch (e) {
            console.log(e);
        }
    }

    getKeywords(str) {
        if (!str.includes(' ')) {
          return str.toLowerCase();
        }
        let result = this.checkStringExist(str);
        if (result) {
          return [str.replace(/[\s]/g, '_').replace(/[\/]/g, '~').toLowerCase()];
        }
        let keywordsNum = (str.match(new RegExp(" ", "g")) || []).length + 1 
        if(keywordsNum > 2) {
          let tempStr = str;
          for (let i = 1; i < keywordsNum; i++){
            let index = tempStr.lastIndexOf(" ");
            let firstPsblKey = str.substring(0,index)
            let secondPsblKey = str.substring(index+1,str.length)
            let result = this.checkStringExist(firstPsblKey);
            if (result || i == keywordsNum -1) {
              let firstKey = [firstPsblKey.replace(/[\s]/g, '_').replace(/[\/]/g, '~').toLowerCase()]
              let secondKeys = this.getKeywords(secondPsblKey);
              return firstKey.concat(secondKeys);
            } 
            tempStr = firstPsblKey;
          }    
        } else {
          return str.split(' ').map((ele) => {return ele.toLowerCase()});
        }
    }

    checkStringExist(str): boolean {
        str = str.toLowerCase();
        return this.allSearchTypesMasters.includes(str)
    }

    fnGetChatsMessages(userId, order_id, chat_id, fromIndex) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = { 'order_id': order_id, 'chat_id': chat_id, 'user_id': userId, fromIndex};
                console.log(requestParameters)
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getChatMessages';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnAddChatsMessages(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'addChatMessage';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnDeleteChatMessage(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'deleteChatMessage';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnEditChatMessage(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'editChatMessage';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnChangeUserMsgStatusToRead(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'changeUserMsgStatusToRead';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnFetchOrderChats(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getChatThreads';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnFetchSingleChatThread(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getSingleChatThread';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnDeleteChatMember(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'deleteChatMember';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }
    
    fnAddChatMember(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'addChatMember';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetProductionOrdersStatusesCount(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                // let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                const requestHeaders = this.fnFetchHttpRequesHeaders(targetUrl);
                let postUrl = targetUrl + 'getProductionOrdersStatusesCount';
                const requestParams = {
                    requestHeaders,
                    postUrl,
                    requestData
                }
                // let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                this.searchCall.next(requestParams);
                this.searchResult.subscribe((responseData)=>{
                    // console.log('getting response',responseData);
                    resolve(responseData)
                },
                (error)=>{
                    console.log('getting error',error);
                })
                // resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetSalesOrdersStatusesCount(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                // let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                const requestHeaders = this.fnFetchHttpRequesHeaders(targetUrl);
                let postUrl = targetUrl + 'getSalesOrdersStatusesCount';
                const requestParams = {
                    requestHeaders,
                    postUrl,
                    requestData
                }
                // let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                this.searchCall.next(requestParams);
                this.searchResult.subscribe((responseData)=>{
                    // console.log('getting response',responseData);
                    resolve(responseData)
                },
                (error)=>{
                    console.log('getting error',error);
                })
                // resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSearchUsers(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const requestHeaders = this.fnFetchHttpRequesHeaders(targetUrl);
                const postUrl = targetUrl + 'searchUsers';
                const requestParams = {
                    requestHeaders,
                    postUrl,
                    requestData
                }
                // const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                this.searchCall.next(requestParams);
                this.searchResult.subscribe((responseData)=>{
                    // console.log('getting response',responseData);
                    resolve(responseData)
                },
                (error)=>{
                    console.log('getting error',error);
                })
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSearchAdminCSDUsers(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const requestHeaders = this.fnFetchHttpRequesHeaders(targetUrl);
                const postUrl = targetUrl + 'searchAdminCSDUsers';
                const requestParams = {
                    requestHeaders,
                    postUrl,
                    requestData
                }
                // const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                this.searchAdminCSDUsersCall.next(requestParams);
                this.searchAdminCSDUsersResult.subscribe((responseData)=>{
                    // console.log('getting response',responseData);
                    resolve(responseData)
                },
                (error)=>{
                    console.log('getting error',error);
                })
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnSearchSPUsers(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const requestHeaders = this.fnFetchHttpRequesHeaders(targetUrl);
                const postUrl = targetUrl + 'searchSPUsers';
                const requestParams = {
                    requestHeaders,
                    postUrl,
                    requestData
                }
                // const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                this.searchSPUsersCall.next(requestParams);
                this.searchSPUsersResult.subscribe((responseData)=>{
                    // console.log('getting response',responseData);
                    resolve(responseData)
                },
                (error)=>{
                    console.log('getting error',error);
                })
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnFetchUnreadOrderChats(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getUnreadThreads';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnFetchUnreadMessagesCount(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getUnreadMessagesCount';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    getallHQUsers(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getAllHQUsers';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }
    
    getOrderProductsDetail(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getOrderProductsDetail';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    getOrderArtworkDetail(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getOrderArtworkDetail';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    getArtworkApprovalImages(requestData) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'getArtworkApprovalImages';
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnAddMemberToChat(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'addMemberToChat';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, data);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnCheckIfOrderCancelled(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'checkIfOrderCancelled';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, data);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnRejectArtwork(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'rejectArtwork';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, data);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnMarkChatReadUnread(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'markChatReadUnread';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, data);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetOrderShippingDetails(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getOrderShippingDetail';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, data);
                console.log('ship-details ', result);
                
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetCategoryWiseProductCounts(reqData) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getCategoryWiseProductCounts';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, reqData);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetAllSizes() {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllSizes';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, null);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetStatusesCount(data) {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = data;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getStatusesCount';
                let result: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetAllPrintMethod() {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = '';
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getAllPrintMethod';
                let method: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(method);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetBankHolidays() {
        try {
            return new Promise(async (resolve, reject) => {
                let requestParameters = '';
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + 'getBankHolidays';
                let method: any = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(method);
            });
        } catch (e) {
            console.log(e);
        }
    }
    fnCreateNotification({ message, isRead, isDismissed, orderId, jobName, to, from }: { message: any; isRead: any; isDismissed: any; orderId: any; jobName?: any; to: any; from?: any; }) {
        try {
            return new Promise(async (resolve, reject) => {
                const targetUrl = environment.config.proxy_url;
                const postUrl = targetUrl + 'notification';
                const requestData = JSON.stringify({ message, isRead, isDismissed, orderId, jobName, userId: to, from });
                const response = await this.fnHttpPostCall(targetUrl, postUrl, requestData);
                resolve(response);
            });
        } catch (e) {
            console.log(e);
        }
    }

    fnGetUnreadNotification(uid) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `getNotification/${uid}?isRead=false`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetAllNotification(uid, pageNumber) {
        try {
            return new Promise(async (resolve, reject) => {
                let offset = 51;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `getNotification/${uid}?page=${pageNumber}&offset=${offset}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnmarkAsAllRead(uid) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `notification/markAllRead/${uid}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnMarkAsReadById(nId,isRead,uid) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `notificationRead/${nId}?changeStatus=${!isRead}&userId=${uid}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetChatUnreadNotification(uid) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `getUnreadChatNotification/${uid}?isRead=false`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetAllChatNotification(uid, pageNumber) {
        try {
            return new Promise(async (resolve, reject) => {
                let offset = 51;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `getUnreadChatNotification/${uid}?page=${pageNumber}&offset=${offset}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnDismissChatNotification(uid, notificationId) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `fnDismissChatNotification/${uid}?notificationId=${notificationId}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnGetAllChatThreadNotification(uid) {
        try {
            return new Promise(async (resolve, reject) => {
                let offset = 51;
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `getChatThreadUnreadNotifications/${uid}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnDismissChatThreadNotification(uid, notificationId) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `DismissChatThreadNotification/${uid}?notificationId=${notificationId}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    fnfetchTotalNotificationCount(uid) {
        try {
            return new Promise(async (resolve, reject) => {
                let targetUrl = environment.config.proxy_url;
                let postUrl = targetUrl + `fetchTotalNotificationCount/${uid}`;
                let requestParameters = {};
                let result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
                resolve(result);
            })
        } catch (err) {
            console.log(err);
        }
    }

    async getPpMemberByUserUID(userUID: Number) {
        try {
          while (Object.keys(this.productionPartners).length === 0) {
            await new Promise(resolve => setTimeout(resolve, 100));
          }
 
          return await this.getProductionPartners(userUID);
        } catch (err) {
          console.log(err);
        }
    }

    fnGetPpMemberByUserUID(userUID: Number) {
        try {
          return new Promise(async (resolve, reject) => {
            const targetUrl = environment.config.proxy_url;
            const postUrl = targetUrl + 'getPpTeamMemberByUserUID';
            const requestParameters = {
              userUID: userUID
            };
            const result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
            resolve(result);
          });
        } catch (e) {
          console.log(e);
        }
      }

    fnGetPpMemberByPpCompanyID(ppCompanyID: Number) {
        try {
          return new Promise(async (resolve, reject) => {
            const targetUrl = environment.config.proxy_url;
            const postUrl = targetUrl + 'getPpTeamMemberByPpCompanyID';
            const requestParameters = {
                ppCompanyID: ppCompanyID
            };
            const result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
            resolve(result);
          });
        } catch (e) {
          console.log(e);
        }
      }

  async fnGetActivePpMembers() {
    try {
      return new Promise(async (resolve, reject) => {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'getAllActivePpTeamMembers';
        const requestParameters = '';
        const result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
  
        resolve(result);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchInitialValues() {
    try {
      return new Promise(async (resolve, reject) => {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'fetchAllPrintingData';
        const requestParameters = '';
        const result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
  
        resolve(result);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async clearRedisFieldCache(key) {
    try {
      return new Promise(async (resolve, reject) => {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'clearRedisFieldCache';
        const requestParameters = { 'key' : key };
        const result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
  
        resolve(result);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getPpCompanies() {
        // Fetch all production partner companies
        this.ppCompanies = await this.fnGetAllPPCompnaies();
  }

  async getListProductionPartners() {
        // Fetch all production partners
        this.listProductionPartners = await this.fnGetAllProductionPartnerUsers();
  }
  async getDefaultPricingData(styleId,supplier) {
    try {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'fetchDefaultPricingData';
        const requestParameters = { 'styleId' : styleId,supplier :supplier };
        // const requestParameters = { 'productId' : styleId};
        const result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
        this.defaultPricings.next(result);
    } catch (e) {
      console.log(e);
      this.defaultPricings.next(null);
    }
  }
  resetDefaultPricings() {
    this.defaultPricings.next(null);
  }
  async calculateSalesTax(taxPayload: any) {
    try {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'salesTaxCalculation';
        const requestParameters = taxPayload;
        const result = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
        return result;
    } catch (e) {
      console.log(e);
      this.defaultPricings.next(null);
    }
  }
  async getArtworkPostionByProduct(productId) {
    try {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'getArtworkPostionByProduct';
        const requestParameters = { 'productId' : productId };
        return  await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);

    } catch (e) {
      console.log(e);
    }
  }
  
  async fetchAndStoreWarehouseAddresses() {
    // Check if warehouse addresses are already stored in sessionStorage
    let warehouseAddresses:any = sessionStorage.getItem('warehouseAddresses');

    if (warehouseAddresses) {
        // Parse the JSON string back into an array of objects
        warehouseAddresses = JSON.parse(warehouseAddresses);
    } else {
        try {
            // Call the API to get the warehouse addresses
            const response = await this.getWarehousesAddresses();

            warehouseAddresses = response;

            // Store the addresses in sessionStorage
            sessionStorage.setItem('warehouseAddresses', JSON.stringify(warehouseAddresses));

        } catch (error) {
            console.error('Error fetching warehouse addresses:', error);
        }
    }

    return warehouseAddresses;
  }

  async getWarehousesAddresses() {
    try {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'getWarehouses';
        // const requestParameters = { 'productId' : productId };
        return  await this.fnHttpPostCall(targetUrl, postUrl, {});

    } catch (e) {
      console.log(e);
    }
  }
  
  async getChargeDetailsByProduct(productId) {
    try {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'getChargeDetails';
        const requestParameters = { 'productId' : productId };
        return  await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);

    } catch (e) {
      console.log(e);
    }
  }
  
  async getPartChargeDetails(productId) {
    try {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'getPartChargeDetails';
        const requestParameters = { 'productId' : productId };
        return  await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);

    } catch (e) {
      console.log(e);
    }
  }

  async initializeMethodsAndPricing() {
    // Fetch active production partner members

    // await this.getDefaultPricingData(4) // Get the product with static product_id `4`
    let initialValues = await this.fetchInitialValues();    
    // this.ppMembers = await this.fnGetActivePpMembers();
    // this.systemSettings = await this.fnGetSystemSettings();
    this.ppMembers = initialValues['activePpMembers'];
    const systemSettings = initialValues['systemSettings'].reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
    }, {});
    this.systemSettings = systemSettings;
    if (Object.keys(this.systemSettings).length > 0) {
        this.isSettingsRetrieved = true;
    } else {
        this.isSettingsRetrieved = false;
    }
    this.productionPricing = initialValues['productionPricings']
  
    // Populate production partner object with user IDs as keys
    for (const user of this.ppMembers) {
      this.productionPartners[user.user_id] = user;
      this.productionPartners[user.user_id]['ppCompanyId'] = user.pp_company_id;
      this.productionPartners[user.user_id]['screen_fees'] = user.screen_fees;
      delete this.productionPartners[user.user_id]['pp_company_id'];
    }
  
    // Set up print method names
    this.allPrintMethodNames = ["Screen Printing", "Direct to Garment", "Embroidery", "Vinyl", "Finishings"];
  
    // Rename the object to pricingObject
    this.pricingObject = {};
  
    // Iterate over each print method name
    for (const value of this.allPrintMethodNames) {
      // Fetch print method data by name
    //   const printMethodData = await this.fnGetPrintMethodByName(value);
      const printMethodData = initialValues['printingMethods'][value];
      this.printMethods[value] = printMethodData;
  
      // Handle Vinyl print method separately
      if (value === 'Vinyl') {
        for (const ppCompany of this.ppCompanies) {
          // Fetch vinyl pricing data
        //   const vinylPricingData = await this.fnGetVinylPricing(printMethodData['print_method_id'], ppCompany.id);
          const vinylPricingData = initialValues['vinylPricingData'][ppCompany.id];
          this.vinylData[ppCompany.id] = vinylPricingData;
  
          // Calculate fnGeneratePricingFunction
        //   const pricingData = await this.fnGeneratePricingFunction(printMethodData['print_method_id'], ppCompany.id);
          const pricingData = initialValues['generatedPricingFunctions'][value][ppCompany.id];
          
          // Store the values in pricingObject using ppCompanyId.print_method_id as the key
          if (!this.pricingObject[ppCompany.id]) {
            this.pricingObject[ppCompany.id] = {};
          }
          this.pricingObject[ppCompany.id][printMethodData['print_method_id']] = pricingData;
        }
      } else {
        for (const ppCompany of this.ppCompanies) {
          // Calculate fnGeneratePricingFunction
        //   const pricingData = await this.fnGeneratePricingFunction(printMethodData['print_method_id'], ppCompany.id);
          const pricingData = initialValues['generatedPricingFunctions'][value][ppCompany.id];
          // Store the value in pricingObject using ppCompanyId.print_method_id as the key
          if (!this.pricingObject[ppCompany.id]) {
              this.pricingObject[ppCompany.id] = {};
            }
            this.pricingObject[ppCompany.id][printMethodData['print_method_id']] = pricingData;
        }
      }
    }
  }

  async getAllProductionUsers() {
    if (this.listProductionPartners.length > 0) {
        return this.listProductionPartners;
    } else {
        // await new Promise(resolve => setTimeout(resolve, 1000));
        return this.getAllProductionUsers();        
    }
  }  

  async getSystemSettings() {  
    while (!this.isSettingsRetrieved) {
        await new Promise(resolve => setTimeout(resolve, 100));
    }
    return this.systemSettings;     
  }  

  async getProductionPartners(userId) {        
    return this.productionPartners[userId];
  }     

  async getPrintMethodData(methodName) {
    return this.printMethods[methodName];
  }

  async getVinylPricing(ppCompanyId) {
    return this.vinylData[ppCompanyId];
  }  

  async getPricingData(printMethodId, ppCompanyId) {        
    return this.pricingObject[ppCompanyId]?.[printMethodId];
  }
  
  async getCouponValidity(couponCode) {
    try {
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'checkDiscountCode';
        const requestParameters = { 'code' : couponCode };
        return await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
    } catch (e) {
      console.log(e);
    }
  }
  async validateZipCode(zipCode, countryCode, state) {
    try {
        console.log('HHH zipCode', zipCode);
        
        const targetUrl = environment.config.proxy_url;
        const postUrl = targetUrl + 'pinCodeValidator';
        const requestParameters = { 'zipCode' : zipCode, 'countryCode': countryCode, 'state': state };
        return await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
    } catch (e) {
      console.log(e);
    }
  } 
    async fetchAllLocationData() {
        try {
            const targetUrl = environment.config.proxy_url;
            const postUrl = `${targetUrl}fetchAllLocationData`;
            const requestParameters = {};
            const response = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
            localStorage.setItem('locationData', JSON.stringify(response));
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async fetchStatesData(countryName) {
        try {
            const targetUrl = environment.config.proxy_url;
            const postUrl = `${targetUrl}fetchAllStateData`;
            const requestParameters = { 'country_name' : countryName}
            const response = await this.fnHttpPostCall(targetUrl, postUrl, requestParameters);
            return response;
        } catch (e) {
            console.log(e);
        }
    }
}